import { Component, input, output } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { MatTooltipModule } from "@angular/material/tooltip";

@Component({
  standalone: true,
  selector: "dso-reset-button",
  imports: [MatIconModule, MatTooltipModule, MatButtonModule],
  templateUrl: "./reset-button.component.html",
})
export class ResetButtonComponent {
  public readonly disabled = input<boolean>(false);
  public readonly tooltip = input<string>();
  public readonly areaLabelButton = input<string>();
  public readonly ariaLabelIcon = input<string>();

  public readonly clicked = output<void>();

  public emitClick(): void {
    this.clicked.emit();
  }
}
