import {
  Component,
  inject,
  OnInit,
  signal,
  WritableSignal,
} from "@angular/core";
import { MatIconModule } from "@angular/material/icon";
import { MAT_SNACK_BAR_DATA } from "@angular/material/snack-bar";
import { TranslateModule } from "@ngx-translate/core";

type ResultSnackbarInput = "SUCCESS" | "ERROR";
type ResultSnackbarIcons = "check_box" | "exclamation_mark";

const messageByStatus: Map<ResultSnackbarInput, string> = new Map([
  ["SUCCESS", "SNACKBAR.SUCCESS_MESSAGE"],
  ["ERROR", "SNACKBAR.ERROR_MESSAGE"],
]);

@Component({
  standalone: true,
  selector: "dso-result-snack-bar",
  imports: [TranslateModule, MatIconModule],
  templateUrl: "./result-snack-bar.component.html",
  styleUrl: "./result-snack-bar.component.scss",
})
export class ResultSnackBarComponent implements OnInit {
  readonly #data: ResultSnackbarInput = inject(MAT_SNACK_BAR_DATA);

  public svgSource: WritableSignal<ResultSnackbarIcons | undefined> =
    signal(undefined);
  public svgAltText: WritableSignal<string | undefined> = signal(undefined);
  public message: WritableSignal<string | undefined> = signal(undefined);

  public ngOnInit(): void {
    if (this.#data === "SUCCESS") {
      this.svgSource.set("check_box");
      this.svgAltText.set("CHANGE_STATUS.SUCCESS_ICON_ALT_TEXT");
    } else {
      this.svgSource.set("exclamation_mark");
      this.svgAltText.set("CHANGE_STATUS.ERROR_ICON_ALT_TEXT");
    }

    this.message.set(messageByStatus.get(this.#data));
  }
}
