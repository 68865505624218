import { CommonModule } from "@angular/common";
import { Component, computed, input, InputSignal } from "@angular/core";
import { MatIconModule } from "@angular/material/icon";
import { TranslateModule } from "@ngx-translate/core";

import { StatusType } from "@app/shared";
import { StatusMetaProvider } from "@app/shared/util/status-meta-provider";

import { Size } from "./size";

@Component({
  standalone: true,
  selector: "dso-status-indicator",
  imports: [TranslateModule, CommonModule, MatIconModule],
  templateUrl: "./status-indicator.component.html",
  styleUrl: "./status-indicator.component.scss",
})
export class StatusIndicatorComponent {
  public readonly status: InputSignal<StatusType> =
    input.required<StatusType>();
  public readonly size: InputSignal<Size> = input<Size>("small");
  public readonly total: InputSignal<number | undefined> = input<
    number | undefined
  >();

  public readonly statusMeta = computed(() =>
    StatusMetaProvider.provide(this.status()),
  );
}
